<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false"> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <v-card :min-height="vsh-250" outlined color="">
            <v-card>
                <mbs-page-tab 
                    :items="SettingsLinks"/>  
            </v-card>
            <v-layout row wrap class="px-4 py-5"> 
                <!-- //others -->
                <v-flex xs6 sm4 lg3 v-for="(link, index) in MyLinks" :key="index">
                    <v-hover>
                        <template v-slot="{ hover  }">
                            <v-card  
                                :to="link.link"
                                :elevation="hover ?12:''"
                                hover  rounded="lg"
                                height="100%"> 
                                <div v-if="MBS.actions.TEXT_UP(link.name) === MBS.actions.TEXT_UP('My profile')">
                                    <v-img v-if="ud?ud.photoURL:false" 
                                        aspect-ratio="1.8" 
                                        :src="ud.photoURL"
                                        lazy-src="https://picsum.photos/10/6?image=3"  >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                    /> 
                                            </v-row>
                                        </template>
                                    </v-img>
                                        
                                    <v-responsive  v-else   
                                        class="primary darken-1"
                                        aspect-ratio="1.8" > 
                                        <v-layout fill-height justify-center align-center>
                                            <v-avatar
                                                size="80"
                                                color="primary darken-2" > 
                                                <span class="white--text headline">
                                                    {{us.displayName ? us.displayName[0] : ""}}
                                                </span>  
                                            </v-avatar>
                                        </v-layout>
                                    </v-responsive> 
                                </div> 

                                <!--Not my profile--> 
                                <v-responsive v-else aspect-ratio="1.5"  >
                                    <v-card height="100%" :color="link.image_background_color" tile>
                                        <v-layout v-if="false" class="ma-0" align-center justify-center fill-height > 
                                            <v-card color="transparent" :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                                <v-img   
                                                    position="center"
                                                    :src="link.image?link.image:MBS.data.noImg" > 
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>  
                                            </v-card>
                                        </v-layout>
                                        <v-layout v-else-if="link.icon" class="ma-0" fill-height justify-center align-center>
                                            <v-icon size="60">{{link.icon}}</v-icon>
                                        </v-layout>    
                                    </v-card>
                                </v-responsive>
                                <v-card-title >
                                    <v-layout class="ma-0" >
                                        <div class="headline" >{{link.name}}</div>
                                        <v-spacer></v-spacer> 
                                        <v-tooltip bottom  > 
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon >
                                                    <v-icon color="primary">info_outline</v-icon>
                                                </v-btn> 
                                            </template>
                                            <span>More Info</span>
                                        </v-tooltip> 
                                    </v-layout>
                                </v-card-title> 
                            </v-card> 
                        </template>
                    </v-hover>
                </v-flex>
            </v-layout> 
        </v-card>
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../../plugins/DATA' 
let PAGE_NAME = "COMPANY-SETTINGS"
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            DATA:DATA
        }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },
            /////////
            SettingsLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("settings",1,true) 
                return thisLinks
            },
            MyLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("cards",2,true) 
                return thisLinks
            },
            PageTaps(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("settings",1,true) 
                return thisLinks
            }
        },
        methods: { 
            clicked(){},
            //LOAD
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
